<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div class="py-1 px-2">
            <div class="d-flex align-items-center justify-content-between mb-1">
              <div>
                <h3 class="mb-0 font-weight-bolder">
                  {{ $t("Mapa") }}
                </h3>
              </div>
              <div v-if="role !== 'prl_cliente'">
                <b-link :to="{ name: 'createLocation' }">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-1 text-nowrap"
                  >
                    {{ $t("AñadirLocalizacion") }}
                  </b-button>
                </b-link>
              </div>
            </div>
            <div v-if="items">
              <l-map
                ref="map"
                :zoom="zoom"
                :center="center"
              >
                <l-tile-layer :url="url" />
                <l-marker
                  v-for="(x, i) in items"
                  :key="i"
                  :lat-lng="[x.latitude, x.longitude]"
                >
                  <l-popup>
                    <div class="text-center">
                      <b-link
                        :to="{
                          name: 'editLocation',
                          params: {
                            id: x.id,
                          },
                        }"
                      >
                        <strong>{{ x.name }}</strong><feather-icon
                          class="ml-50"
                          icon="Edit2Icon"
                        />
                      </b-link>
                    </div>
                  </l-popup>
                </l-marker>
              </l-map>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card
          no-body
          class="mb-1"
        >
          <div
            class="d-flex justify-content-between align-items-center px-1 py-1"
          >
            <div>
              <h3 class="m-0 py-1">
                {{ $t("Localizaciones") }}
              </h3>
            </div>

            <div class="d-flex align-items-center">
              <span
                class="ml-1 cursor-pointer"
                @click="visibleFilter = !visibleFilter"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="20"
                />
              </span>
            </div>
          </div>

          <div>
            <b-collapse
              id="collapse"
              v-model="visibleFilter"
              class="mt-2"
            >
              <div class="px-2">
                <b-row>
                  <b-col
                    v-if="role === 'admin_empresa' && !currentClient"
                    sm="4"
                  >
                    <b-form-group
                      :label="$t('Cliente')"
                      label-for="filter-client"
                    >
                      <v-select
                        v-model="client"
                        label="name"
                        :filterable="true"
                        :searchable="true"
                        :options="listClients"
                        :placeholder="$t('Cliente')"
                      >
                        <template
                          slot="option"
                          slot-scope="option"
                        >
                          {{ option.name }}
                        </template>
                        <template
                          slot="selected-option"
                          slot-scope="option"
                        >
                          {{ option.name }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="$t('Busqueda')"
                      label-for="filter-search"
                    >
                      <b-row>
                        <b-col sm="8">
                          <b-form-input
                            v-model="searchTerm"
                            :placeholder="$t('Busqueda')"
                            type="text"
                            class="d-inline-block"
                          />
                        </b-col>
                        <b-col
                          sm="4"
                          style="padding-left: 0px"
                        >
                          <b-button
                            variant="primary"
                            @click="handleSearch"
                          >
                            {{ $t("Buscar") }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="displayColumns"
          >
            <template #cell(client)="data">
              <span v-if="data.item.client">
                {{ data.item.client.name }}
              </span>
            </template>
            <template #cell(coordenadas)="data">
              <span v-if="data.item.client">
                {{ printCoordenates(data.item.latitude) }}, {{ printCoordenates(data.item.longitude) }}
              </span>
            </template>
            <template #cell(parent)="data">
              <span v-if="data.item.parent">
                {{ data.item.parent.name }}
              </span>
              <span v-else> - </span>
            </template>
            <template #cell(principal)="data">
              <b-form-radio
                v-model="principal"
                name="principal-radios"
                :value="data.item.id">
              </b-form-radio>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'editLocation', params: { id: data.item.id } }"
                  class="mr-1"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    size="16"
                  />
                </b-link>
                <span
                  class="cursor-pointer text-danger"
                  @click="deleteLocation(data.item.id, data.item.name)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                  />
                </span>
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BFormInput,
  BLink,
  BCard,
  BTable,
  BButton,
  BCol,
  BRow,
  BCollapse,
  BFormGroup,
    BFormRadio,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import {
  LMap, LMarker, LTileLayer, LPopup,
} from 'vue2-leaflet'
import { latLng, Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
import vSelect from "vue-select";

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    BCard,
    BFormInput,
    BTable,
    BButton,
    BCol,
    BRow,
    BLink,
    BFormGroup,
    BCollapse,
    LMap,
    LTileLayer,
    LPopup,
    LMarker,
    vSelect,
    BFormRadio,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 5,
      log: [],
      dir: false,
      visibleFilter: false,
      client: '',
      principal: '',
      columns: [
        {
          label: this.$t('Nombre'),
          key: 'name',
        },
        {
          label: this.$t('Cliente'),
          key: 'client',
        },
        {
          label: this.$t('Direccion'),
          key: 'address',
          thStyle: { 'min-width': '375px' },
        },
        {
          label: this.$t('Coordenadas'),
          key: 'coordenadas',
        },
        {
          label: this.$t('topLocation'),
          key: 'parent',
        },
        {
          label: this.$t('Acciones'),
          key: 'actions',
          class: 'text-right',
        },
      ],
      searchTerm: '',
      executePrincipal: 0,
    }
  },
  computed: {
    ...mapGetters({
      currentCompany: 'companies/getCurrentCompany',
      currentClient: 'clients/getCurrentClient',
      items: 'locations/getItems',
      totalItems: 'locations/getTotalItems',
      currentUser: 'auth/getUser',
      role: 'auth/getRole',
      listClients: 'clients/getItems',
    }),
    displayColumns() {
      const columnsClient = this.columns.filter(({ key }) =>  key !== 'client')
      const columnsPRLClient = this.columns.filter(({ key }) =>  key !== 'actions')

      if (this.currentClient && this.role !== 'prl_cliente') {
        return columnsClient
      }
      if(this.role === 'prl_cliente') {
        return columnsPRLClient
      }
      return this.columns
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    center() {
      return latLng(40.2085, -3.713)
    },
  },
  async created() {
    await this.getListClients({
      company: this.currentCompany.id,
      page: 1,
      per_page: 999999,
      search: '',
    })
    await this.chargeData()
  },
  methods: {
    ...mapActions({
      list: 'locations/getListLocations',
      delete: 'locations/delete',
      locationPrincipal: 'locations/principal',
      getListClients: 'clients/getListClients',
    }),
    selectedPrincipal() {
      if (this.items.length > 0) {
        this.items.forEach(function (entry) {
          if (entry.principal) {
            this.principal = entry.id
          }
        }, this)
      }
    },
    async chargeData() {
      let clients = this.currentClient ? [this.currentClient.id] : []
      if (this.role !== 'admin_cliente' && clients === []) {
        clients = this.client ? [this.client.id] : []
      }
      await this.list({
        clients,
        page: 1,
        per_page: 9999999,
        search: this.searchTerm,
      })
      await this.selectedPrincipal()
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    printCoordenates(data) {
      if (data) {
        return parseFloat(data).toFixed(6)
      }
      return ''
    },
    deleteLocation(id, name) {
      this.$bvModal
        .msgBoxConfirm(this.$t('EliminarConfirm', { nombre: name }), {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: this.$t('Eliminar'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
  },
  watch: {
    client() {
      this.chargeData()
    },
    principal() {
      if (this.executePrincipal) {
        this.locationPrincipal(this.principal)
      } else {
        this.executePrincipal = 1
      }
    },
  },
  mounted() {
    const map = this.$refs.map.mapObject
    const searchControl = new GeoSearchControl({
      provider: new OpenStreetMapProvider(),
      showMarker: false,
      // ... some more options
    })
    map.addControl(searchControl)
  },
}
</script>
<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 450px;
  }
}
.principal{
  display:flex;
  justify-content: space-around;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
